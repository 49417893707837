
.main-box{

  display: flex;
  flex-direction: column;
  margin-top:2rem;
    margin-bottom: 1rem;
    .input{
        max-width: 12rem;
        margin: 0 auto;
        margin-bottom: 0.5rem;
    }
    .submit-btn{
        margin-top:1.5rem;
        max-width: 9rem;

        margin: 0 auto;
        background-color: rgb(255, 177, 88);
        border: transparent;
        font-weight: 700;
        font-size: 2rem;
    }
    h4{
        text-align: center;
        font-size: 4rem;
        color:red;
        margin-bottom:10rem;
    }
    .card-box{
        margin-top:1rem;

    }
    .status-card{
        margin: 0 auto;
        max-width: 20rem;
        min-height:20rem;
        .card-title{
            color:red;
            background-color: red;
        }
        p{
            color:red;
        }
        h5{
            font-size: 1rem;
        }
        h6{
            color:red;
        }
    }
}