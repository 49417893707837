.palacio{
    // max-width: 20rem;

    .flags{
        margin-top:0rem;
        display: flex;
        flex-direction: row;
;


        // margin: 0 auto;

    h2{
        font-size: 2.5rem;
        padding-left: -10px;

    }
    }
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100%;
    // margin-top:5rem;
    // margin-bottom:5rem;
    // max-width:50rem;
    padding:20px;
    .t-card{
        min-width: 22rem;
        background-color: rgb(255, 204, 147);
    }
    .input{
        // margin-bottom:1rem;
        color:rgb(255, 115, 0);
        font-weight: 700;
    }
    .mb-2{
        .prepend{
        font-weight: 400;
}
    }
    .input-message{
        margin-bottom:1rem;
        min-height: 10rem;
    }
    .send{
        margin-top:1rem;

        background-color:rgb(86, 163, 86);
        font-size:2rem;
        border-color:greenyellow;
        margin-bottom:10rem;

    }
    .edit{
        margin-top:1.5rem;
        // max-width:22rem;
        max-width: 50rem;
        padding: 10px;

        background-color:rgb(255, 147, 143);
        font-size:2rem;
        border-color:rgb(255, 113, 47);
        // margin-bottom:1rem;
    }

    
}
h4{
    text-align: left;
}
.country-div{

    .country{
    
    margin: 0 auto;

    font-size: 2rem;
    background-color: rgb(255, 123, 0);
    border:0;}
}

.platica-logo{
    margin: 0 auto;
    // margin-top:-5rem;
    max-width: 15rem;
}

.sub-header-text{
    // margin-top:-7rem;
    margin-bottom:2rem;
    .p{
        font-weight:800;
        color:red;
    }

    .link{
        margin-top:-2rem;
        font-size:1.7rem;
        margin-left:0.5rem;
        font-weight: 650;
        color:rgb(248, 89, 49);
        background-color:transparent;
        border-color:transparent;
        text-decoration: underline;
    }
}